import React from "react";
import Link from "../../components/common/Link";

import withLayout from "../../components/hoc/withLayout";

const Endeavors = () => {
  return (
    <div className="my-12 text-center">
      <h3 className="text-2xl leading-6 font-medium text-gray-900 my-6">
        Hello!
      </h3>
      <p>
        Sometimes I code. Sometimes I write. Sometimes I take photographs.
        Sometimes I do other stuff. I repost memes. Good vibes only.
      </p>
      <p className="my-3">
        I'm me, I do me, and I chill -{" "}
        <Link className="text-indigo-600" to="https://twitter.com/KDTrey5">
          Kevin Durant
        </Link>
      </p>
    </div>
  );
};

export default withLayout(Endeavors);
